export const environment = {
  production: true,
  name: 'production',
  CMSDomain: 'https://cms.extemporeapp.com',
  StudentDomain: 'https://student.extemporeapp.com',
  NapiStudentDomain: 'https://napi.extemporeapp.com',
  ServerDomain: 'https://napibackend.extemporeapp.com',
  NApiDomain: 'https://napibackend.extemporeapp.com/api',
  StoreDomain: 'https://napi.extemporeapp.com',
  CloudFrontUrl: 'https://d1fckxfv9cdtt9.cloudfront.net',
  EdlinkClientId: '767ee380-a73f-460a-9f46-676d9c952118',
  EdlinkState: '3xt3mp0r3pr0d',
  CleverDomain: 'https://api.clever.com/v2.0',
  CleverId: '4cbf09b0f5a7b5a6067f',
  CleverDistrict: '5e73d2cce69cd40001074ea6',
  ClassLinkId: 'c165029480670167f8da6f359b0375ec34b757f26649',
  ClassLinkState: '3xt3mp0r3pr0d',
  FirebaseConfig: {
    apiKey: "AIzaSyApMSeHVGDYiPNx-WOlqsMY-fJuRoWVvtA",
    authDomain: "extempore-cc762.firebaseapp.com",
    databaseURL: "https://extempore-cc762.firebaseio.com",
    storageBucket: "extempore-cc762.appspot.com",
  },
  FirebaseUserRoot: 'users',
  FirebaseAdminUsers: 'adminusers',
  Buckets: {
    class: 'extempore-prod-skillareasimages',
    attempt: 'extempore-prod-attempts',
    questionAttachment: 'extempore-prod-expertfiles',
    profileImage: 'extempore-prod-profileimages',
    logo: 'extempore-prod-organization-logos',
    assets: 'extempore-assets',
  },
  awsLambdaUrls: {
    mediaUploadPresignedUrl: 'https://3zg5mzxhkou6pp7ozehnjy4cvu0cufgh.lambda-url.us-east-1.on.aws/'
  },
  stripeKey: 'pk_live_jO6AKNUtwmG9ndOEAchX5WTW',
  analyticsProperty: 'UA-66422851-1',
  captchaSiteKey: '6LcB-bIlAAAAALiDESxRncTLsH1a39s0C8unfOZx',
  intercomAppId: 'fjlywnz6',
  intercomSecretKey: 'Z45xknudlkJcxe--i3T6ENVA64vmYHWVHbzM-v1V',
  intercomBearerToken: 'Bearer dG9rOmY5ZWE5ZTc5XzEzYzZfNGM0Y19hY2I5X2ZkY2RjMTk5Nzk4YToxOjA=',
  copyrightYear: new Date().getFullYear()
};
