import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { io } from "socket.io-client";
import { AuthService } from './auth.service';
import { DeviceService } from './device.service';
import { UserSessionService } from './user-session.service';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    public socket = io(environment.ServerDomain, {
        transports: ["websocket"],
        withCredentials: true
    });

    /**
     * Keep a subject when token is successfuly sent to sockets i.e. the user is setup
     * in the server so we can continue sending other events
     */
    public tokenSent = new BehaviorSubject<boolean>(null);

    /**
     * We have a problem of receiving a lot of token events from token.subscribe so we need this values to check if we are receiving the same token that's already sent and not send it again
     */
    private tokenThatWasSent: string;

    constructor(
        private authService: AuthService,
        private device: DeviceService,
        private userSessionService: UserSessionService
    ) {
        if (!this.device.isApp()) {
            this.socket.on('askForToken', () => {
                this.authService.token.subscribe(token => {
                    if (token && token !== this.tokenThatWasSent) {
                        this.tokenThatWasSent = token;
                        this.socket.emit('tokenReceived', token);
                        this.tokenSent.next(true);
                    }
                });
            });

            this.authService.userLoggedOut.subscribe(didUserLoggedOut => {
                if (didUserLoggedOut) {
                    this.tokenThatWasSent = null;
                    this.socket.emit('userLoggedOut');
                    this.tokenSent.next(false);
                }
            });

            this.socket.on('logOutUser', () => {
                this.authService.logout(true);
            });
        } else {
            this.authService.token.subscribe(token => {
                if (token && token !== this.tokenThatWasSent) {
                    this.tokenThatWasSent = token;
                    this.userSessionService.userInApp.next({inApp: true, token: token});
                }
            });

            this.authService.userLoggedOut.subscribe(didUserLoggedOut => {
                if (didUserLoggedOut) {
                    this.tokenThatWasSent = null;
                }
            });
        }
    }

    public listenToSyncRoomParticipants(token: string, roomId: number) {
        this.socket.emit('listenToSyncRoomParticipants', {token: token, roomId: roomId});
    }

    public stopListeningToSyncRoomParticipants(token: string, roomId: number) {
        this.socket.emit('stopListeningToSyncRoomParticipants', {token: token, roomId: roomId});
    }

    public userInSyncRoom(token: string, roomId: string, version: number, instructor: boolean) {
        this.socket.emit('joinedSyncRoom', {token: token, roomId: roomId, version: version, instructor: instructor});
    }

    public userLeftRoom(token: string, roomId: string, version: number, instructor: boolean) {
        this.socket.emit('leftSyncRoom', {token: token, roomId: roomId, version: version, instructor: instructor});
    }

    public currentUsersInSyncRoom(roomId: string) {
        this.socket.emit('currentUsersInSyncRoom', {roomId: roomId});
    }

    public syncRoomAskForHelp(roomId: string, askForHelp: boolean) {
        this.socket.emit('syncRoomAskForHelp', {roomId: roomId, askForHelp: askForHelp});
    }

    public joinedAsyncRoom(token: string, roomId: string) {
        this.socket.emit('joinedAsyncRoom', {token: token, roomId: roomId});
    }

    public leftAsyncRoom(token: string, roomId: string) {
        this.socket.emit('leftAsyncRoom', {token: token, roomId: roomId});
    }

    public newAsyncRoomAttempt(roomId: string) {
        this.socket.emit('newAsyncRoomAttempt', {roomId: roomId});
    }
}
